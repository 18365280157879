import { Button } from "@material-ui/core";
import {
  ComponentProps,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";

type ButtonProps = ComponentProps<typeof Button>;

export function AutoClickButton(props: ButtonProps & { activateMs: number }) {
  const { activateMs, onClick, children, ...rest } = props;
  const [, forceRender] = useState(0);

  const activatingInRealtime = useRef(activateMs);
  const countdownDisabled = useRef(false);
  const $domButton = useRef<HTMLButtonElement>();

  const handleClick = useCallback(
    (...args: Parameters<ButtonProps["onClick"]>) => {
      countdownDisabled.current = true;

      if (onClick) {
        onClick(...args);
      }

      // We need to set the disabled setting async in order to trigger the click
      const idleCallback = window.requestIdleCallback ?? setTimeout;
      
      idleCallback(() => {
        $domButton.current?.setAttribute("disabled", "");
      });
    },
    []
  );

  useEffect(() => {
    function decrement() {
      setTimeout(() => {
        if (countdownDisabled.current) {
          return;
        }

        activatingInRealtime.current = activatingInRealtime.current - 1000;

        forceRender((c) => c + 1);

        if (activatingInRealtime.current > 0) {
          decrement();
        } else {
          $domButton.current?.click();
        }
      }, 1000);
    }

    decrement();
  }, []);

  return (
    <Button innerRef={$domButton} onClick={handleClick} {...rest}>
      {children}

      {` (${activatingInRealtime.current / 1000})`}
    </Button>
  );
}
