export function setCancelButtonVisibility(isVisible: boolean) {
  if (isVisible) {
    try {
      //@ts-ignore
      DataActivationController.showCancelButtonSelected();

      return;
    } catch (err) {}
  } else {
    try {
      //@ts-ignore
      DataActivationController.hideCancelButtonSelected();

      return;
    } catch (err) {}
  }
}

export function setVerifyingIndicatorVisibility(isVisible: boolean) {
  if (isVisible) {
    try {
      //@ts-ignore
      DataActivationController.showVerifyingIndicator();

      return;
    } catch (err) {}
  } else {
    try {
      //@ts-ignore
      DataActivationController.hideVerifyingIndicator();

      return;
    } catch (err) {}
  }
}

// Locally store the ICC and SMDPAddress used for completing the flow for android
let storedIcc: string | null = null;
let storedSmdpAddress: string | null = null;

export function close() {
  // iOS
  try {
    // @ts-ignore
    if (DataActivationController) {
      //@ts-ignore
      DataActivationController.doneSelected();

      return;
    }
  } catch (err) {}

  // Android
  try {
    // @ts-ignore
    if (AndroidSubscriptionManager) {
      //@ts-ignore
      AndroidSubscriptionManager.onPlanSelectionCompleted(
        1,
        1,
        storedIcc,
        storedSmdpAddress,
        ""
      );

      return;
    }
  } catch (err) {}

  console.log(`🛸 No connection to app detected. Unable to perform \`close\``);
}

export function connect(
  icc: string,
  equipmentId: string,
  smdpAddress?: string // for Android only
) {
  // iOS
  try {
    // @ts-ignore
    if (DataActivationController) {
      //@ts-ignore
      DataActivationController.dataPlanAccountUpdatedWithInfo({
        eid: equipmentId,
        iccid: icc,
      });

      return;
    }
  } catch (err) {}

  // Android
  try {
    // @ts-ignore
    if (AndroidSubscriptionManager) {
      if (!smdpAddress) {
        throw new Error(
          "No `smdpAddress` provided! Unable to perform `connect`"
        );
      }

      // Store the values put in here, in order for using them
      // when we close the app
      storedIcc = icc;
      storedSmdpAddress = smdpAddress;

      // //@ts-ignore
      // The documentation says that this will download the profile in the background
      // and not close the app, however in practive this does not work
      // // AndroidSubscriptionManager.onProfileDownloadBackground(
      // //   1,
      // //   1,
      // //   storedIcc,
      // //   storedSmdpAddress,
      // //   ""
      // // );
      return;
    }
  } catch (err) {}

  console.log(
    `🛸 No connection to app detected. Unable to perform \`connect\``
  );
}
