import { motion } from "framer-motion";
import { ComponentProps } from "react";

export function MainRouteView(props: ComponentProps<typeof motion["main"]>) {
  return (
    <motion.main
      initial={{ x: "10%", opacity: 0 }}
      animate={{ x: "0", opacity: 1 }}
      exit={{ x: "-40%", opacity: 0 }}
      {...props}
    />
  );
}
