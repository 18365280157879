import { SvgIcon, Typography } from "@material-ui/core";
import ErrorIcon from "@tdcerhverv/parrotfish/icons/ErrorFilled";
import { useEffect } from "react";
import {
  close,
  setCancelButtonVisibility,
  setVerifyingIndicatorVisibility,
} from "~/app-driver";
import { AutoClickButton } from "./AutoClickButton";
import { MainRouteView } from "./MainRouteView";
import * as Sentry from "@sentry/remix";

export function ErrorBoundary({ error }: any) {
  useEffect(() => {
    Sentry.captureException(error);
  }, []);

  useEffect(() => {
    setCancelButtonVisibility(true);
    setVerifyingIndicatorVisibility(false);
  }, []);

  return (
    <MainRouteView className="failure">
      <div className="failure__icon">
        <SvgIcon component={ErrorIcon} viewBox="0 0 48 48" />
      </div>

      <div className="failure__header">
        <Typography variant="h5">Der skete en fejl</Typography>
      </div>

      <div className="failure__text">
        <Typography variant="body2">
          Der er sket en fejl under aktiveringen af dit smartwatch. Prøv igen
          lidt senere. (fejlkode {getErrorCode("X34")})
        </Typography>
      </div>

      <pre>{JSON.stringify(error, null, 4)}</pre>

      <div className="failure__close">
        <AutoClickButton
          variant="outlined"
          activateMs={1000 * 60}
          onClick={() => close()}
        >
          Luk
        </AutoClickButton>
      </div>
    </MainRouteView>
  );
}

function getErrorCode(defaultCode: string) {
  if (globalThis.navigator) {
    if (!globalThis.navigator.onLine) {
      return "X53";
    }
  }

  return defaultCode;
}
